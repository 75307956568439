import React from 'react';
import { ThreeCol } from '../style/ThreeCol';
import styled from 'styled-components';
import { Color } from '../style/Color';
import { dropShadow } from '../style/dropShadow';
import { InternalPage } from '../style/InternalPage';
import { useStaticQuery, graphql } from 'gatsby';
import { Projects } from '../graphql/types';

export type Props = {};

const Project = styled.div`
	overflow: hidden;
	margin-bottom: 20px;
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: center;
	background-color: ${Color.BG};
	padding: 20px;

	position: relative;
	cursor: pointer;

	transition: all 0.5s;
	border: 0px solid rgba(0, 0, 0, 0);
	/* height: 460px; */
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border: 0px solid ${Color.HILIGHT};
		transition: border 0.5s;
	}

	&:hover {
		&::after {
			border: 10px solid ${Color.HILIGHT};
		}

		transform: scale(1);
		img {
			transform: scale(1.15);
		}
	}

	img {
		display: block;
		box-sizing: border-box;
		object-fit: fill;
		width: 100%;
		transition: transform 0.5s;
		${dropShadow};
	}
`;

const Component: React.FC<Props> = props => {
	const result: Projects = useStaticQuery(graphql`
		query Projects {
			allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/project/" } }) {
				edges {
					node {
						frontmatter {
							title
							url
							poster
							priority
						}
					}
				}
			}
		}
	`);

	const projects = result.allMarkdownRemark.edges
		.map(e => e.node.frontmatter)
		.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
	// .sort((a, b) => a.priority - b.priority);

	return (
		<InternalPage>
			<h1>Past projects include:</h1>
			<section>
				<ThreeCol>
					{projects.map((p, i) => (
						<Project title={p.title} onClick={() => window.open(p.url)} key={i}>
							<img src={`${p.poster}`} />
						</Project>
					))}
				</ThreeCol>
			</section>
		</InternalPage>
	);
};

export default Component;
