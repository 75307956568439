import styled from 'styled-components';

export const ThreeCol = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& > div {
		width: 32%;
	}

	@media (max-width: 768px) {
		& > div {
			width: 48%;
		}
	}
	@media (max-width: 550px) {
		& > div {
			width: 100%;
		}
	}
`;
